import "@/assets/css/_reset.scss";
import "@/assets/css/_common.scss";
import { createApp } from "vue";
import App from "@/App.vue";
import { router } from "./router";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import { createI18n } from "vue-i18n";
import ch from "@/utils/zh";
import en from "@/utils/en";
import Helper from "@/utils/helper";
import { Vue3Mq } from "vue3-mq";
import VueGtag from "vue-gtag-next";

const app = createApp(App);

const yuyan = navigator.language.split("-")[0];
let yuyans;
switch (yuyan) {
  case "zh":
    yuyans = "ch";
    break;
  case "en":
    yuyans = "en";
    break;
  default:
    yuyans = "en";
}

let localLang;
switch (localStorage.getItem("user_lang")) {
  case "zh":
    localLang = "ch";
    break;
  case "ch":
    localLang = "ch";
    break;
  case "en":
    localLang = "en";
    break;
  default:
    localLang = "en";
}

const i18n = createI18n({
  locale: localLang || yuyans,
  messages: {
    ch: ch,
    zh: ch,
    en: en,
  },
});

app.use(Vue3Mq, {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
});

if (document.location.hostname === process.env.VUE_APP_ANALYTICS_HOST_NAME) {
  app.use(VueGtag, {
    property: {
      id: "G-Y7YTXQNTSQ",
      router,
    },
  });
}
app.use(router);
app.use(ElementPlus, {
  locale: localLang || yuyans,
});
app.use(i18n);
app.config.globalProperties.$HelperTools = Helper;
app.mount("#app");
