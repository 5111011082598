import * as VueRouter from "vue-router";
import { trackRouter } from "vue-gtag-next";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/view/home/index"),
  },
  {
    path: "/chain/blocks",
    name: "Blocks",
    component: () => import("@/view/blockList/index"),
  },
  {
    path: "/chain/block/:Number",
    name: "Block",
    component: () => import("@/view/block/index"),
  },
  {
    path: "/chain/block/countdown/:Number",
    name: "BlockCountdown",
    component: () => import("@/view/blockCountdown/index"),
  },
  {
    path: "/chain/txs",
    name: "Txs",
    component: () => import("@/view/txList/index"),
  },
  {
    path: "/chain/tx/:Hash",
    name: "Tx",
    component: () => import("@/view/tx/index"),
  },
  {
    path: "/chain/txs/:User",
    name: "UserTxs",
    component: () => import("@/view/txUser/index"),
  },
  {
    path: "/chain/txs/:User/:primaryPage/:secondaryPage/:pageSize",
    name: "OldUserTxs",
    redirect: (to) => {
      return {
        name: "UserTxs",
        query: {
          page: to.params.primaryPage,
          pageSecondary: to.params.secondaryPage,
        },
        params: {
          ...to.params,
          pageSize: to.params.pageSize,
        },
      };
    },
  },
  {
    path: "/chain/accounts",
    name: "Accounts",
    component: () => import("@/view/accountList/index"),
  },
  {
    path: "/chain/accounts/:page/:pageSize",
    name: "OldAccounts",
    redirect: (to) => {
      return {
        name: "Accounts",
        query: {
          page: to.params.page,
        },
        params: {
          ...to.params,
          pageSize: to.params.pageSize,
        },
      };
    },
  },
  {
    path: "/token/validators",
    name: "Validators",
    component: () => import("@/view/validatorList/index"),
  },
  {
    path: "/token/plt",
    name: "PLT",
    component: () => import("@/view/plt/index"),
  },
  {
    path: "/token/:Contract",
    name: "ContractDetail",
    component: () => import("@/view/contractDetail"),
  },
  {
    path: "/erc-20",
    name: "ERC-20",
    component: () => import("@/view/erc-20List/index"),
  },
  {
    path: "/erc-721",
    name: "ERC-721",
    component: () => import("@/view/erc-721List/index"),
  },
  {
    path: "/nft/:NFT/:Token",
    name: "ERC-721TokenDetail",
    component: () => import("@/view/erc-721TokenDetail/index"),
  },
  {
    path: "/erc-1155",
    name: "ERC-1155",
    component: () => import("@/view/erc-1155List/index"),
  },
  {
    path: "/nft/:Contract/:Token",
    name: "ERC-1155TokenDetail",
    component: () => import("@/view/erc-1155TokenDetail/index"),
  },
];

export const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  routes,
});

trackRouter(router);
