export default {
  language: {
    zhName: "简体中文",
    enName: "English",
    zh: "ZH",
    en: "EN",
  },
  header: {
    title: "Palettescan",
    home: "HOME",
    blockchain: "BLOCKCHAIN",
    token: "TOKENS",
    blocks: "Blocks",
    transactions: "Transactions",
    accounts: "Accounts",
    nfts: "NFTs",
    validators: "Validators",
  },
  home: {
    search: "Search",
    placeholder: "NFTs, Address, Block Height, TX Hash, Contract Hash",
    summary: "Summary",
    height: "Block Height",
    txs: "Total Transactions",
    validator: "Validators",
    accounts: "Total addresses",
    nfts: "Total NFTs",
    block: "Latest Blocks",
    tx: "Latest Transactions",
    all: "View All",
    validators: "Validators",
    time: "Block time",
    overview: "Overview",
    accountsdescription:
      "Total number of addresses that have received or sent a transaction at least once.",
  },
  blocklist: {
    name: "Blocks",
    height: "Height",
    txns: "Txns",
    Validators: "Validators",
    time: "Created Time",
    totalof: "Total of  ",
    block: "  blocks",
  },
  txlist: {
    name: "Transactions",
    hash: "Txn Hash",
    status: "Status",
    height: "Height",
    time: "Created Time",
    totalof: "Total of  ",
    block: "  data",
    from: "From",
    to: "To",
  },
  accountlist: {
    name: "PLT Accounts",
    rank: "Rank",
    address: "Address",
    balance: "PLT Balance",
    percent: "Percent",
    graph: "Graph",
  },
  nftlist: {
    name: "NFT List",
    totalof: "Total of  ",
    block: "  data",
    rank: "#",
    contract: "Contract",
    token: "Token",
    txs: "Transactions",
    address: "Holders",
    time: "Created Time",
    tokenname: "Token",
    supply: "Supply",
  },
  validatorlist: {
    name: "Validator",
    rank: "Rank",
    address: "Address",
    delegate: "Delegate Factor",
    amount: "Stake Amount",
    percent: "Percentage",
    graph: "Graph",
  },
  tx: {
    name: "Transaction Details",
    txhash: "Transaction Hash",
    hash: "Hash",
    block: "Block",
    from: "From",
    to: "to",
    gas: "Gas",
    price: "Gas Price",
    data: "Data",
    time: "Time",
    value: "Value",
    details: "Details",
    contract: "Contract",
    for: "For",
    tokenId: "Token ID",
    status: "Status",
    success: "SUCCESS",
    failed: "FAILED",
  },
  txuser: {
    for: "Transactions for",
    overview: "Overview",
    address: "Address",
    balance: "PLT Balance",
    transactions: "Transactions",
    transactions_sp: "Tx",
    nfts: "NFTs",
    internaltransactions: "Internal Transactions",
    internaltransactions_sp: "Internal Tx",
    nfttransactions: "NFT Transfers",
    nfttransactions_sp: "NFT",
    erc20transactions: "ERC20 Transfers",
    erc20transactions_sp: "ERC20",
    contract: "Contract",
    contractname: "Name",
    symbol: "Symbol",
    tokenid: "Token ID",
    token: "Token",
    parenthash: "Parent Txn Hash",
    value: "Value",
    name: "Transactions",
    hash: "Txn Hash",
    status: "Status",
    height: "Height",
    time: "Created Time",
    totalof: "Total of  ",
    block: "  data",
    from: "From",
    to: "To",
    calculatingnfttransactions:
      "Calculating total number of NFT Transactions...",
  },
  block: {
    name: "Block Details",
    height: "Height",
    txs: "Transactions",
    time: "Time",
    and: "and",
    inthisblock: "in this block",
    gaslimit: "Gas Limit",
    gasused: "GasUsed",
    txlist: "Transactions",
    difficulty: "Difficulty",
    rank: "#",
    hash: "Hash",
    countdown: "Countdown",
  },
  nft: {
    overview: "Overview",
    total: "Total Supply",
    holders: "Holders",
    tx: "Transfers",
    contract: "Contract",
    site: "Official Site",
    inventory: "Inventory",
    hash: "Txn Hash",
    from: "From",
    to: "To",
    id: "TokenID",
    rank: "Rank",
    quantity: "Quantity",
    percentage: "Percentage",
    owner: "Owner",
    events: "Events",
    data: "Method",
    topic1: "Topic1",
    topic2: "Topic2",
    topic3: "Topic3",
    topic4: "Topic4",
    transactionHash: "Txn Hash",
  },
  nfttoken: {
    overview: "Overview",
    tokenid: "Token ID",
    tx: "Transfers",
    site: "Official Site",
    inventory: "Inventory",
    owner: "Owner",
    contract: "Contract",
    hash: "Txn Hash",
    from: "From",
    to: "To",
    id: "TokenID",
  },
  plttoken: {
    name: "PLT",
  },
  blockcountdown: {
    days: "Days",
    hours: "Hours",
    minutes: "Minutes",
    seconds: "Seconds",
    estimateddate: "Estimated Target Date",
    targetblock: "Countdown for Block",
    currentblock: "Current Block",
    remainingblocks: "Remaining Blocks",
  },
  message: {
    copysuccess: "Copy successfully",
    searchfailed: "Sorry, the content you are looking for cannot be found",
  },
  list: {
    csvexport: "[ Download CSV Export ]",
    filter: "Filter",
    clear: "Clear",
  },
  contracts: {
    erc20: "ERC-20",
    erc721: "ERC-721",
    erc1155: "ERC-1155",
    totalof: "Total of  ",
    block: "  data",
    rank: "#",
    contract: "Contract",
    token: "Token",
    txs: "Transactions",
    address: "Holders",
    time: "Created Time",
    tokenname: "Token",
    supply: "Supply",
    detail: {
      total: "Total Supply",
      holders: "Holders",
      tx: "Transfers",
      events: "Events",
      site: "Official Site",
      value: "Value",
    },
  },
  shared: {
    overview: "Overview",
    from: "From",
    to: "To",
    txHash: "Txn Hash",
    amount: "Amount",
    percentage: "Percentage",
    owner: "Owner",
    rank: "Rank",
  },
};
