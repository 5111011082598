
import { computed, defineComponent, provide, ref } from "vue";
import SPHeader from "@/components/SPHeader.vue";
import PCHeader from "@/components/PCHeader.vue";
import { useMq } from "vue3-mq";

export default defineComponent({
  components: {
    SPHeader,
    PCHeader,
  },
  setup() {
    const drawer = ref(false);
    provide("drawer", drawer);
    const mq = useMq();
    const isSP = computed(() => mq.current === "xs");

    return {
      mq,
      isSP,
    };
  },
});
