<template>
  <div id="app">
    <Header />
    <router-view />
  </div>
</template>

<script>
import Header from "@/components/HeaderWrapper.vue";
export default {
  name: "App",
  components: {
    Header,
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: rgba(250, 250, 250, 1);
  min-height: 100vh;
}

html {
  overflow-x: hidden;
  overflow-y: auto;
}
body {
  width: 100vw;
  overflow: hidden;
  padding-left: calc(100vw - 100%);
  padding-right: calc(100vw - 100%);
}
</style>
